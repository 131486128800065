import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import type { ResultPagesType } from 'types/matchData';

const useTanstackQuery = () => {
  const queryClient = useQueryClient();

  const setTimeQueryOptions = (time: number) => {
    return {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * time,
    };
  };

  const infiniteQueryOptions = {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (lastPage: ResultPagesType) => lastPage.next ?? undefined,
    initialPageParam: 0,
  };

  const invalidateQueries = useCallback(
    async (
      queryKeyList: (string | number | object)[],
      isExactlyEqual = false,
    ) => {
      if (isExactlyEqual) {
        await queryClient.invalidateQueries({ queryKey: queryKeyList });
      } else {
        await queryClient.invalidateQueries({
          predicate: (query) =>
            queryKeyList.some((key) => query.queryKey.includes(key)),
        });
      }
    },
    [queryClient],
  );

  // 쿼리를 리셋하는 함수
  // 첫번째 인자에 넘긴 리스트에 원소를 하나라도 포함하는 캐시에 대해서 모두 지운다. -> 예시) 모든 경기 캐시 삭제
  // 두번째 인자로 true값을 넣으면 첫번째 쿼리키와 정확하게 일치하는 캐시만 지운다. -> 예시) 특정 캐시만 삭제
  const resetQueries = useCallback(
    async (queryKeyList: string[], isExactlyEqual = false) => {
      if (isExactlyEqual) {
        await queryClient.resetQueries({ queryKey: queryKeyList });
      } else {
        await queryClient.resetQueries({
          predicate: (query) =>
            queryKeyList.some((key) => query.queryKey.includes(key)),
        });
      }
    },
    [queryClient],
  );

  return {
    infiniteQueryOptions,
    invalidateQueries,
    resetQueries,
    setTimeQueryOptions,
  };
};

export default useTanstackQuery;
