import type { ReactNode } from 'react';

import useTranslation from 'next-translate/useTranslation';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const MobileMessageDialogComponent = ({
  title,
  content,
  open,
  actionText,
  onClose,
  onAction,
}: {
  title: string;
  content: string | ReactNode;
  open: boolean;
  actionText?: string;
  onClose: () => void;
  onAction: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{
        sx: { padding: '24px 30px' },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 2 }}>
        <Typography variant="h5Bold">{title}</Typography>
      </DialogTitle>

      <DialogContent sx={{ p: 0, mb: 2 }}>
        {typeof content === 'string' ? (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {content}
          </Typography>
        ) : (
          content
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end', p: 0 }}>
        <Button
          variant="textAssistive"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          {t('close')}
        </Button>
        <Button
          variant="textPrimary"
          size="large"
          onClick={(e) => {
            e.stopPropagation();
            onAction();
          }}
          sx={{ px: 1 }}
        >
          {actionText || t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileMessageDialogComponent;
