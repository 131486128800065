import { useCallback } from 'react';

import useAxios from 'hooks/useAxios';

import type { ServerUserUpdateRequestBody, userSearchType } from 'types/auth';

const useAuthApi = () => {
  const { studioAuthAxiosV1, liveAuthAxiosV1 } = useAxios();

  const getUserNicknameExistsInLiveServer = useCallback(
    async (nickname: string) =>
      await liveAuthAxiosV1<{ result: boolean; recommend?: string }>({
        method: 'GET',
        url: `/users/${nickname}/check`,
      }),
    [liveAuthAxiosV1],
  );

  const updateUser = useCallback(
    async (uid: string, body: ServerUserUpdateRequestBody) =>
      await liveAuthAxiosV1({
        method: 'PUT',
        url: `/users/${uid}`,
        data: body,
      }),
    [liveAuthAxiosV1],
  );

  const updateStudioUser = useCallback(
    async (uid: string, parameter?: string) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/users/${uid}${parameter}`,
      }),
    [studioAuthAxiosV1],
  );

  const updateAutoPlaySetting = useCallback(
    async (uid: string, autoPlayEnabled: boolean) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/users/${uid}?autoPlayEnabled=${autoPlayEnabled}`,
      }),
    [studioAuthAxiosV1],
  );

  const updateCookieSetting = useCallback(
    async (uid: string, isAllowed: boolean) =>
      await studioAuthAxiosV1({
        method: 'PATCH',
        url: `/users/cookie/${uid}?allowed=${isAllowed}`,
      }),
    [studioAuthAxiosV1],
  );

  const getIsUserFollowing = useCallback(
    async (searchingUid: string) =>
      await studioAuthAxiosV1<boolean>({
        method: 'GET',
        url: `/users/following/${searchingUid}`,
      }),
    [studioAuthAxiosV1],
  );

  const followUser = useCallback(
    async (followingUid: string) =>
      await studioAuthAxiosV1({
        method: 'POST',
        url: `/users/follow/${followingUid}`,
      }),
    [studioAuthAxiosV1],
  );

  const getFollowingList = useCallback(
    async (queries: string) =>
      await studioAuthAxiosV1({
        method: 'GET',
        url: `/users/following${queries}`,
      }),
    [studioAuthAxiosV1],
  );

  const checkMatchAuth = useCallback(
    async (matchUid: string) =>
      await studioAuthAxiosV1({
        method: 'GET',
        url: `/users/matchAuth/${matchUid}`,
      }),
    [studioAuthAxiosV1],
  );

  const generateDeleteToken = useCallback(
    async () =>
      await liveAuthAxiosV1({
        method: 'POST',
        url: '/users/token-for-delete',
      }),
    [liveAuthAxiosV1],
  );

  const deleteManager = useCallback(
    async (permissionId: number) =>
      await studioAuthAxiosV1({
        method: 'DELETE',
        url: `/channel/permission/${permissionId}`,
      }),
    [studioAuthAxiosV1],
  );

  const getStudioUserInfoByIdentifier = useCallback(
    async (identifier: string) => {
      const queryParams = new URLSearchParams();
      queryParams.append('identifier', identifier);
      return await studioAuthAxiosV1<userSearchType[]>({
        method: 'GET',
        url: `/users?${queryParams.toString()}`,
      });
    },
    [studioAuthAxiosV1],
  );

  return {
    checkMatchAuth,
    deleteManager,
    followUser,
    generateDeleteToken,
    getFollowingList,
    getIsUserFollowing,
    getStudioUserInfoByIdentifier,
    getUserNicknameExistsInLiveServer,
    updateAutoPlaySetting,
    updateCookieSetting,
    updateStudioUser,
    updateUser,
  };
};

export default useAuthApi;
