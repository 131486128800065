import type { ReactNode } from 'react';

import type { ButtonProps, SxProps } from '@mui/material';
import { Button } from '@mui/material';

const ResponsiveButton = ({
  xsVariant,
  smVariant,
  variant,
  xsSize,
  smSize,
  children,
  sx,
  ...props
}: {
  xsVariant?: ButtonProps['variant'];
  smVariant?: ButtonProps['variant'];
  xsSize?: ButtonProps['size'];
  smSize?: ButtonProps['size'];
  children?: ReactNode;
  sx?: SxProps;
} & Omit<ButtonProps, 'size' | 'sx'>) => {
  return (
    <>
      <Button
        variant={xsVariant || variant}
        size={xsSize}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          ...sx,
        }}
        {...props}
      >
        {children}
      </Button>
      <Button
        variant={smVariant || variant}
        size={smSize}
        sx={{
          display: { xs: 'none', sm: 'flex' },
          ...sx,
        }}
        {...props}
      >
        {children}
      </Button>
    </>
  );
};

export default ResponsiveButton;
