import { useRouter } from 'next/router';

import type { SxProps } from '@mui/material';
import { Box, Divider } from '@mui/material';

import ResponsiveBannerAdCard from 'components/organisms/GoogleAds/ResponsiveBannerAdCard';

import { FooterText } from './Footer';

const ADS_DISALLOWED_PAGE_LIST = [
  '/control/[matchId]',
  '/control/app/[matchId]',
  '/event/[eventId]',
  '/settings',
];

const CommonFooterText = ({
  footerLabel,
  sx,
}: {
  footerLabel: string;
  sx?: SxProps;
}) => <FooterText footerLabel={footerLabel} sx={{ color: '#aeaeba', ...sx }} />;

const CommonFooter = () => {
  const router = useRouter();

  const isAdsAllowedPage = !ADS_DISALLOWED_PAGE_LIST.includes(router.route);

  return (
    <Box id="gtm-footer-area" component="footer">
      {isAdsAllowedPage ? (
        <ResponsiveBannerAdCard
          type="small"
          adId="모든_푸터상단_배너_공통"
          sx={{ my: 3 }}
        />
      ) : null}

      <Divider />

      <Box
        sx={{
          p: { xs: '24px', sm: '40px' },
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            columnGap: { xs: '16px', sm: '32px' },
            flexWrap: 'wrap',
            mb: 2,
          }}
        >
          <CommonFooterText footerLabel="termOfUse" />
          <CommonFooterText
            footerLabel="privacyPolicy"
            sx={{ fontWeight: 700 }}
          />
          <CommonFooterText footerLabel="appInquiry" />
          <CommonFooterText footerLabel="businessInquiry" />
          <CommonFooterText footerLabel="advertise" />
          <CommonFooterText footerLabel="broadcastInquiry" />
        </Box>

        <Box>
          <CommonFooterText footerLabel="company" />
          <CommonFooterText footerLabel="address" />
        </Box>

        <CommonFooterText footerLabel="copyright" />
      </Box>
    </Box>
  );
};

export default CommonFooter;
