import { memo } from 'react';

import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import type { DialogTitleProps, SxProps } from '@mui/material';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';

import ResponsiveTypography from 'components/commons/ResponsiveTypography';

interface DefaultDialogTitleProps extends Omit<DialogTitleProps, 'sx'> {
  title: string;
  subTitle?: string;
  onClose: () => void;
  sx?: SxProps;
}

const DefaultDialogTitle = ({
  title,
  subTitle,
  onClose,
  sx,
  ...props
}: DefaultDialogTitleProps) => (
  <DialogTitle
    component="div"
    sx={{
      position: 'relative',
      p: { xs: '16px 8px', sm: '40px 30px' },
      height: { xs: '56px', sm: 'auto' },
      ...sx,
    }}
    {...props}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {/* 모바일 < 버튼 */}
      <IconButton
        onClick={onClose}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          alignItems: 'center',
          width: '40px',
          height: '40px',
          flex: 1,
        }}
      >
        <BackIcon sx={{ width: '24px', height: '24px' }} />
      </IconButton>

      <ResponsiveTypography
        xsVariant="subtitle1Bold"
        smVariant="h3Bold"
        sx={{
          flex: 20,
          textAlign: { xs: 'center', sm: 'left' },
          color: { xs: 'text.secondary', sm: 'text.primary' },
        }}
      >
        {title}
      </ResponsiveTypography>

      <Box sx={{ flex: 1, minWidth: '40px' }} />
    </Box>
    {subTitle ? (
      <Typography
        variant="h5"
        sx={{
          display: { xs: 'none', sm: 'block' },
          mt: 1,
          color: 'text.secondary',
        }}
      >
        {subTitle}
      </Typography>
    ) : null}

    {/* 웹 x 버튼 */}
    <IconButton
      onClick={onClose}
      sx={{
        position: 'absolute',
        top: '22px',
        right: '18px',
        alignItems: 'center',
        display: { xs: 'none', sm: 'block' },
        width: '40px',
        height: '40px',
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

export default memo(DefaultDialogTitle);
