import type { MouseEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

const usePopoverControl = <T = HTMLButtonElement>() => {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);

  const open = useCallback((event: MouseEvent<T>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  return {
    anchorEl,
    isOpen,
    open,
    close,
  };
};

export default usePopoverControl;
