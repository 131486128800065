import type { MouseEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import BackIcon from '@mui/icons-material/ArrowBackIosRounded';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import type { AppBarProps } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  AppBar as MuiAppBar,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useNotificationApi from 'api/hooks/useNotificationApi';

import StudioLogoIcon from 'components/atoms/Icons/StudioLogoIcon';
import { Divider } from 'components/atoms/Spacing';
import MoreOptionButton from 'components/commons/MoreOptionButton';
import HistoryMenu from 'components/molecules/Notification/HistoryMenu';
import ProfileMenu from 'components/molecules/ProfileMenu';
import ComponentGuard from 'components/templates/ComponentGuard';

import useActiveAuth from 'hooks/useActiveAuth';
import useAppBarElementData from 'hooks/useAppBarElementData';
import useAppBarMenuList from 'hooks/useAppBarMoreButtonList';
import useCheckDevice from 'hooks/useCheckDevice';
import useCommonSx from 'hooks/useCommonSx';
import useTanstackQuery from 'hooks/useTanstackQuery';

import errorMessage from 'utils/errorMessage';
import { APP_BAR_MOBILE_HEIGHT } from 'utils/getConstantNumber';

import SearchBanner from '../SearchBanner';

const AppBar = ({
  anchorElUser,
  handleAnchorElUser,
  handleSideMenu,
  sx,
  ...rest
}: {
  anchorElUser: HTMLElement | null;
  handleSideMenu: (isOpen: boolean) => void;
  handleAnchorElUser: (event: MouseEvent<HTMLElement> | null) => void;
} & AppBarProps) => {
  const { channel, isSubscribedUser, signIn, signOut } = useActiveAuth();
  const { updateNotificationHistoryIdList } = useNotificationApi();
  const { asPath, route, push, pathname } = useRouter();
  const { isMobile } = useCheckDevice();
  const isScrollDown = useScrollTrigger({ disableHysteresis: !isMobile });
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { logo, backBtn, pageTitle, searchBtn, notificationBtn, moreBtn } =
    useAppBarElementData(isMobile);
  const { resetQueries } = useTanstackQuery();
  const { oneLineSx } = useCommonSx();
  const { menuList } = useAppBarMenuList();

  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isSearchBannerOpen, setIsSearchBannerOpen] = useState(false);
  const [notificationIdList, setNotificationIdList] = useState<number[]>([]);
  const [anchorElNotification, setAnchorElNotification] =
    useState<HTMLElement | null>(null);
  const [isNotificationHistoryOpen, setNotificationHistoryOpen] =
    useState<boolean>(false);

  const isAboutPage = route === '/about';

  useEffect(() => {
    setProfileMenuOpen(false);
    setIsSearchBannerOpen(false);
  }, [asPath]);

  const handleOpenProfileMenu = (event: MouseEvent<HTMLElement>) => {
    handleAnchorElUser(event);
    setAnchorElNotification(null);
    setProfileMenuOpen(true);
  };

  const handleCloseProfileMenu = () => {
    handleAnchorElUser(null);
    setAnchorElNotification(null);
    setProfileMenuOpen(false);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      handleCloseProfileMenu();
    } catch (error) {
      enqueueSnackbar(errorMessage(error), {
        variant: 'error',
      });
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      handleSideMenu(open);
    };

  const addStateWindowHistory = () => {
    window.history.pushState(
      { searchBannerOpen: true },
      '',
      window.location.href,
    );
  };

  const handleBackFromMobile = useCallback(
    (event: { state: { searchBannerOpen: boolean } }) => {
      if (!event.state || !event.state.searchBannerOpen) {
        setIsSearchBannerOpen(false);
      }
    },
    [],
  );

  const handleOpenNotificationMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
    setNotificationHistoryOpen(true);
  };

  const handleCloseNotificationMenu = async () => {
    setAnchorElNotification(null);
    setNotificationHistoryOpen(false);
    updateNotificationHistoryIdList(notificationIdList);
    queryClient.resetQueries({
      queryKey: ['notifications', channel?.uid],
    });
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackFromMobile);
    return () => window.removeEventListener('popstate', handleBackFromMobile);
  }, [handleBackFromMobile]);

  const navigateToMainPage = () => {
    if (route === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      resetQueries(['matchList', 'channelList', 'eventList']);
    } else push('/?scrollToTop=true');
  };

  return (
    <MuiAppBar
      id="gtm-header-area"
      sx={{
        color: 'inherit',
        boxShadow: 'none',
        position: 'sticky',
        paddingRight: '0 !important',
        zIndex: 1000,

        ...(isMobile &&
          !isAboutPage && {
            top: isScrollDown ? `-${APP_BAR_MOBILE_HEIGHT}px` : 0,
            transition: 'top .2s',
          }),

        ...sx,
      }}
      {...rest}
    >
      {isSearchBannerOpen ? (
        isMobile ? (
          <SearchBanner.Mobile
            open={isSearchBannerOpen}
            onCloseSearchBanner={() => {
              setIsSearchBannerOpen(false);
              window.history.back();
            }}
          />
        ) : (
          <SearchBanner.Desktop
            isSearchBarOpen={isSearchBannerOpen}
            onCloseSearchBanner={() => {
              setIsSearchBannerOpen(false);
            }}
          />
        )
      ) : null}

      <Toolbar
        disableGutters
        sx={{
          px: { xs: 1, sm: 2 },
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          className="gtm-side-btn"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer(true)}
          sx={{
            display: { xs: 'none', sm: 'flex' },
            width: 48,
            height: 48,
          }}
        >
          <MenuIcon />
        </IconButton>

        {logo ? (
          <h1
            className="gtm-home-btn"
            style={{ margin: 0, padding: 0, flex: 1, cursor: 'pointer' }}
          >
            <Box
              aria-label="Go to main page"
              onClick={() => navigateToMainPage()}
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                px: 1,
              }}
            >
              <Typography
                variant="body2"
                style={{
                  opacity: 0,
                  width: '1px',
                  height: '1px',
                  overflow: 'hidden',
                  position: 'absolute',
                }}
              >
                {t('meta.title')}
              </Typography>
              <StudioLogoIcon sx={{ width: '180px', height: '30px' }} />
            </Box>
          </h1>
        ) : (
          <h1
            style={{
              margin: 0,
              padding: 0,
              opacity: 0,
              width: 0,
              height: 0,
              overflow: 'hidden',
            }}
          >
            {t('meta.title')}
          </h1>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 0.5,
            flex: 1,
          }}
        >
          {backBtn ? (
            <IconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={() => {
                if (window.history.length === 1) push('/');
                else window.history.back();
              }}
            >
              <BackIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          ) : null}

          {pageTitle ? (
            <Typography
              component="h1"
              variant="subtitle1Bold"
              sx={{
                ...oneLineSx,
                textAlign: 'center',
                flex: 1,
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                color: 'text.secondary',
              }}
            >
              {t(`appbarTitle.${route}`)}
            </Typography>
          ) : null}
        </Box>

        {notificationBtn && (
          <ComponentGuard
            loadingFallback={
              <Skeleton
                variant="circular"
                sx={{
                  margin: '4px',
                }}
                width={32}
                height={32}
              />
            }
          >
            <>
              <IconButton
                className="gtm-noti-btn"
                onClick={handleOpenNotificationMenu}
                sx={{
                  width: '40px',
                  height: '40px',
                  '&:focus:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <NotificationsIcon />
              </IconButton>
              <Menu
                MenuListProps={{ sx: { p: 0 } }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: isMobile ? '0px' : '16px',
                    width: isMobile ? '100%' : '360px',
                    maxWidth: isMobile ? '100%' : '360px',
                    ...(isMobile && {
                      top: '0px !important',
                      left: '0px !important',
                      maxHeight: 'none',
                      height: '100vh',
                    }),
                  },
                }}
                anchorEl={anchorElNotification}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                id="view-histories"
                keepMounted
                onClose={handleCloseNotificationMenu}
                open={
                  Boolean(anchorElNotification) && isNotificationHistoryOpen
                }
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <HistoryMenu
                  onClose={handleCloseNotificationMenu}
                  onNotificationIdList={(idList: number[]) =>
                    setNotificationIdList(idList)
                  }
                />
              </Menu>
            </>
          </ComponentGuard>
        )}

        {searchBtn ? (
          <IconButton
            aria-label="Search button"
            size={isMobile ? 'small' : 'medium'}
            onClick={() => {
              if (!isSearchBannerOpen && isMobile) addStateWindowHistory();
              setIsSearchBannerOpen(!isSearchBannerOpen);
            }}
            sx={{
              display: 'block',
              width: { xs: '32px', sm: '40px' },
              height: { xs: '32px', sm: '40px' },

              '&:focus:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <SearchIcon />
          </IconButton>
        ) : null}

        {moreBtn ? (
          <MoreOptionButton className="gtm-share-btn" optionList={menuList} />
        ) : null}

        <ComponentGuard
          loadingFallback={
            !isMobile && (
              <>
                <Skeleton
                  sx={{
                    margin: '5px',
                  }}
                  variant="circular"
                  width={32}
                  height={32}
                />
              </>
            )
          }
          nonLoginFallback={
            <Button
              variant="contained"
              onClick={signIn}
              sx={{
                display: { xs: 'none', lg: 'block' },
                mx: 1,
                whiteSpace: 'nowrap',
                fontWeight: 900,
                background:
                  'linear-gradient(115deg,#37C556 13.75%,#15B3B3 82.09%)',
              }}
            >
              {t('sign in')}
            </Button>
          }
        >
          <Box>
            <Tooltip
              title={
                !isMobile
                  ? `${channel.channelName} (${
                      isSubscribedUser ? t('subscriber') : t('user')
                    })`
                  : t('profileMenu.setting')
              }
            >
              <IconButton
                className="gtm-profile-btn"
                onClick={handleOpenProfileMenu}
                size="small"
                sx={{
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                <Avatar
                  alt={channel?.channelName}
                  src={channel?.profileUrl || ''}
                  sx={{
                    height: 32,
                    width: 32,
                  }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              MenuListProps={{ sx: { p: 0 } }}
              sx={{
                '& .MuiPaper-root': {
                  mt: '19px',
                  borderRadius: '8px',
                  minWidth: 260,
                  maxWidth: 350,
                },
              }}
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              id="settings-menu"
              keepMounted
              onClose={handleCloseProfileMenu}
              open={Boolean(anchorElUser) && isProfileMenuOpen}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <ProfileMenu
                onClose={handleCloseProfileMenu}
                handleSignOut={handleSignOut}
              />
            </Menu>
          </Box>
        </ComponentGuard>
      </Toolbar>
      {pathname === '/settings' && isMobile === false && <Divider />}
    </MuiAppBar>
  );
};

export default AppBar;
