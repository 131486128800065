import React, { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import SettingsIcon from '@mui/icons-material/Settings';
import type { PaletteMode } from '@mui/material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { GET_MANAGED_CHANNEL_LIST_KEY } from 'api/hooks/useChannelApi';
import useChannelPermissionInvitedApi from 'api/hooks/useChannelPermissionInvitedApi';
import useNotificationApi from 'api/hooks/useNotificationApi';

import useActiveAuth from 'hooks/useActiveAuth';
import useCheckDevice from 'hooks/useCheckDevice';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type { ChannelPermissionInvitedData } from 'types/channelPermissionInvited';
import {
  NotificationMessageType,
  StudioEventType,
  Tab,
} from 'types/notification';

import { notificationContent } from 'utils/notificationContent';
import { notificationTimeFormatter } from 'utils/notificationTimeFormatter';

const HeaderTypographySx = (
  selected: boolean,
  unread: boolean,
  mode: PaletteMode,
) => {
  return {
    fontWeight: 600,
    color: selected ? (mode === 'light' ? 'black' : 'white') : 'gray.500',
    '&:after': unread
      ? {
          content: '"ㅤ"',
          width: '6px',
          height: '6px',
          backgroundColor: '#f34c55',
          borderRadius: '50%',
          position: 'absolute',
          top: ' 8px',
          right: '6px',
        }
      : {},
  };
};

const ChannelPermission = ({
  data: { id, owner, createdAt },
}: {
  data: ChannelPermissionInvitedData;
}) => {
  const { t } = useTranslation('common');
  const { isMobile } = useCheckDevice();
  const { patchChannelPermissionInvited } = useChannelPermissionInvitedApi();
  const contentData = {
    studioEventType: StudioEventType.CHANNEL_PERMISSION_REQUEST,
    messageType: NotificationMessageType.MYACTIVITY,
    channelName: owner.channel.channelName,
    matchTitle: '',
    eventTitle: '',
    title: '',
    body: '',
  };
  const content = notificationContent(contentData, t);
  const { invalidateQueries } = useTanstackQuery();

  const [afterAccepted, setAfterAccepted] = useState<boolean | null>(null);

  const translateTimeLabel = (props: { type: string; data?: number }) => {
    if (props.type === 'now' || props.type === 'yesterday') {
      return t(`notificationMenu.timeLabel.${props.type}`);
    } else {
      return `${props?.data}${t(`notificationMenu.timeLabel.${props.type}`)}`;
    }
  };

  const { mutate: handlePatchChannelPermissionInvited } = useMutation({
    mutationFn: async ({ id, accepted }: { id: number; accepted: boolean }) => {
      await patchChannelPermissionInvited(id, accepted);
      setAfterAccepted(accepted);
    },
    onSuccess: () => {
      invalidateQueries(GET_MANAGED_CHANNEL_LIST_KEY);
    },
  });

  return (
    <>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <Avatar
          src={owner.profileUrl ?? ''}
          sx={{ width: '60px', height: '60px' }}
        />
        <Box
          sx={{
            width: 'calc(23.5rem - 100px)',
            minWidth: 0,
          }}
        >
          <Typography
            variant="body3"
            sx={{
              color: 'gray.600',
              display: 'flex',
              width: '100%',
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
            }}
          >
            {content?.title?.start}
            <Typography
              component="span"
              variant="body3Bold"
              sx={{
                color: 'gray.600',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {content?.title?.bold}
            </Typography>
            {content?.title?.end}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'themeInverseColor',
            }}
          >
            {content?.description?.start}
            <Typography
              component="span"
              variant="body2Bold"
              sx={{
                color: 'themeInverseColor',
              }}
            >
              {content?.description?.bold}
            </Typography>
            {content?.description?.end}
          </Typography>
          <Typography
            variant="body3"
            sx={{
              color: 'gray.600',
              paddingTop: '4px',
            }}
          >
            {translateTimeLabel(notificationTimeFormatter(createdAt))}
          </Typography>
          {afterAccepted === null ? (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                gap: '8px',
                paddingTop: '16px',
              }}
            >
              <Button
                variant="outlined"
                color="inherit"
                sx={{ width: '50%' }}
                onClick={() =>
                  handlePatchChannelPermissionInvited({
                    id: id,
                    accepted: false,
                  })
                }
              >
                {t('notificationMenu.refuse')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: '50%' }}
                onClick={() =>
                  handlePatchChannelPermissionInvited({
                    id: id,
                    accepted: true,
                  })
                }
              >
                {t('notificationMenu.accept')}
              </Button>
            </Box>
          ) : (
            <>
              {afterAccepted ? (
                <Button
                  sx={{ width: '100%', marginTop: '16px' }}
                  disabled
                  variant="outlined"
                >
                  {t('notificationMenu.accepted')}
                </Button>
              ) : (
                <Button
                  sx={{ width: '100%', marginTop: '16px' }}
                  disabled
                  variant="contained"
                >
                  {t('notificationMenu.refused')}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <Divider
        sx={{
          height: '1px',
          padding: '0px',
          backgroundColor: 'gray.100',
          borderColor: 'gray.100',
          opacity: '0.8',
          margin: isMobile ? '0px' : '0px 16px',
        }}
      />
    </>
  );
};

const ChannelPermissionInvitedList = () => {
  const { getChannelPermissionInvited } = useChannelPermissionInvitedApi();
  const { user } = useActiveAuth();

  const { data, isLoading } = useQuery({
    queryKey: ['channelPermissionInvited', user?.id],
    queryFn: () => getChannelPermissionInvited(),
  });

  if (isLoading) {
    return <Skeleton />;
  }

  if (data) {
    return (
      <Box component="section">
        {data.map((item) => (
          <ChannelPermission data={item} key={item.id} />
        ))}
      </Box>
    );
  }
  return null;
};

const HistoryMenu = ({
  onClose,
  onNotificationIdList,
}: {
  onClose: () => void;
  onNotificationIdList: (idList: number[]) => void;
}) => {
  const { getNotifications, updateNotificationHistoryIdList } =
    useNotificationApi();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const { t } = useTranslation('common');
  const {
    palette: { mode },
  } = useTheme();
  const { isAuthenticatedUser, token, user } = useActiveAuth();
  const router = useRouter();

  const { isMobile } = useCheckDevice();

  const [tab, setTab] = useState<Tab>(Tab.Notifications);
  const [tabUnreadList, setTabUnreadList] = useState<boolean[]>([false, false]);
  const [scrolledUnreadIdList, setScrolledUnreadIdList] = useState<number[]>(
    [],
  );

  const {
    isError,
    isLoading,
    isSuccess,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['notifications', user?.id, { type: tab }],
    queryFn: async ({ pageParam = 0 }) =>
      await getNotifications(tab, pageParam, 12),
    getNextPageParam: (lastPage) => lastPage.next ?? undefined,
    enabled: Boolean(isAuthenticatedUser) && token !== '',
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    refetchOnMount: true,
    staleTime: 1000 * 60 * 5,
    initialPageParam: 0,
  });

  const translateTimeLabel = (props: { type: string; data?: number }) => {
    if (props.type === 'now' || props.type === 'yesterday') {
      return t(`notificationMenu.timeLabel.${props.type}`);
    } else {
      return `${props?.data}${t(`notificationMenu.timeLabel.${props.type}`)}`;
    }
  };

  const clickSettings = () => {
    router.push('/settings?tab=common');
    onClose();
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // 읽음 처리 하기 위해 scroll해서 얻어온 데이터 id들 모아두기
  useEffect(() => {
    const idList = data?.pages
      .map((item) => item.notificationHistories)
      .reduce((acc, currentArray) => acc.concat(currentArray), []);
    if (idList) {
      setScrolledUnreadIdList(
        idList.filter((item) => !item.isRead)?.map((item) => item.id),
      );
    }
  }, [data]);

  useEffect(() => {
    if (scrolledUnreadIdList) {
      onNotificationIdList(scrolledUnreadIdList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledUnreadIdList]);

  // 탭 전환 관련 코드 시작 //
  const clickTabFunction = async (beforeTab: Tab, clickedTab: Tab) => {
    if (beforeTab === clickedTab) return;
    // 탭 변경할 때 마다 새로운 알림 있을 경우 갱신해줘야 하니 refetchQueries 작업
    await queryClient.refetchQueries({
      queryKey: ['notifications', user?.id, { type: clickedTab }],
    });
    // 스크롤 됐던 모든 데이터들 탭 변경 시 읽음 처리.
    await updateNotificationHistoryIdList(scrolledUnreadIdList);
    setTab(clickedTab);
  };

  useEffect(() => {
    const checkTabRead = async () => {
      // 해당 탭의 unread 존재여부 확인 시, limit 1로만 하는 게 더 나으니 limit 매개변수 추가.
      if (token && tab) {
        if (tab === Tab.Notifications) {
          const res = await getNotifications(Tab.Ads, 0, 1);
          // isRead가 false인 item이 하나라도 존재할 경우
          const hasUnread = res?.notificationHistories.some(
            (item) => !item.isRead,
          );
          setTabUnreadList([false, hasUnread]);
        } else if (tab === Tab.Ads) {
          const res = await getNotifications(Tab.Notifications, 0, 1);
          const hasUnread = res?.notificationHistories.some(
            (item) => !item.isRead,
          );
          setTabUnreadList([hasUnread, false]);
        }
      }
    };
    checkTabRead();
  }, [getNotifications, tab, token]);
  // 탭 전환 관련 코드 끝 //

  const HeaderComp = () => {
    return (
      <Box sx={{ height: '86px' }}>
        <Box
          component="section"
          sx={{
            color: 'themeInverseColor',
            height: '43px',
            padding: '10px 20px 10px 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'space-between' : 'center',
          }}
        >
          {isMobile && (
            <IconButton
              sx={{ width: '24px', height: '24px' }}
              onClick={onClose}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <Typography variant="h5Bold">
            {t('notificationMenu.title')}
          </Typography>
          {isMobile && <Box sx={{ width: '24px', height: '24px' }} />}
        </Box>
        <Box
          component="section"
          sx={{
            height: '42px',
            padding: '0px 16px 0px 16px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Button
              className="gtm-noti-btn"
              onClick={async () => {
                await clickTabFunction(tab, Tab.Notifications);
              }}
              sx={{
                height: '42px',
                borderRadius: '0px',
                padding: '10px',
                paddingBottom: tab === Tab.Notifications ? '7px' : '10px',
                borderBottom: tab === Tab.Notifications ? `3px solid` : '',
                borderColor: 'primaryColor.500',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={HeaderTypographySx(
                  tab === Tab.Notifications,
                  tabUnreadList[0],
                  mode,
                )}
              >
                {t('notificationMenu.tab.notifications')}
              </Typography>
            </Button>
            <Button
              className="gtm-noti-btn"
              onClick={async () => {
                await clickTabFunction(tab, Tab.Ads);
              }}
              sx={{
                height: '42px',
                borderRadius: '0px',
                padding: '10px',
                paddingBottom: tab === Tab.Ads ? '7px' : '10px',
                borderBottom: tab === Tab.Ads ? `3px solid` : '',
                borderColor: 'primaryColor.500',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={HeaderTypographySx(tab === Tab.Ads, tabUnreadList[1], mode)}
              >
                {t('notificationMenu.tab.ads')}
              </Typography>
            </Button>
          </Box>
          <IconButton
            className="gtm-general-setting-btn"
            aria-label="settings"
            sx={{
              color: 'gray.600',
              padding: '0px',
              height: '20px',
              marginTop: '12px',
            }}
            onClick={clickSettings}
          >
            <SettingsIcon sx={{ width: '20px', height: '20px' }} />
          </IconButton>
        </Box>
        <Divider
          sx={{
            height: '1px',
            backgroundColor: 'gray.100',
            borderColor: 'gray.100',
            margin: '0px',
          }}
        />
      </Box>
    );
  };

  if (isError) {
    return (
      <List
        id="gtm-noti-area"
        aria-labelledby="nav-list"
        component="nav"
        sx={{ padding: '0px', height: '720px' }}
      >
        <HeaderComp />
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            maxHeight: isMobile ? 'none' : '634px',
          }}
        >
          <Box sx={{ padding: '2px' }}>
            <Alert severity="error">{t(`notificationMenu.error`)}</Alert>
          </Box>
        </Box>
      </List>
    );
  }

  if (isLoading) {
    return (
      <List
        id="gtm-noti-area"
        aria-labelledby="nav-list"
        component="nav"
        sx={{ padding: '0px', height: '720px' }}
      >
        <HeaderComp />
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            maxHeight: isMobile ? 'none' : '634px',
          }}
        >
          {Array.from({ length: 12 }, (_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              sx={{
                height: '98px',
                borderBottom: '1px solid',
                borderColor: 'gray.50',
              }}
            />
          ))}
        </Box>
      </List>
    );
  }

  if (isSuccess && data) {
    return (
      <List
        id="gtm-noti-area"
        aria-labelledby="nav-list"
        component="nav"
        sx={{ padding: '0px', height: '720px' }}
      >
        <HeaderComp />
        <Box
          sx={{
            height: '100%',
            overflowY: 'auto',
            maxHeight: isMobile ? 'none' : '634px',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'deprecatedGray.70',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: mode === 'dark' ? 'deprecatedGray.20' : 'white',
            },
          }}
        >
          {tab === Tab.Notifications && <ChannelPermissionInvitedList />}
          {data.pages[0].notificationHistories.length > 0 ? (
            <>
              {data.pages.map((page) => (
                <Fragment key={page.next ?? 0}>
                  {page.notificationHistories?.map((item) => {
                    const contentData = {
                      studioEventType: item.notificationEvent.studioEventType,
                      messageType: item.notificationEvent.messageType,
                      channelName: item.notificationEvent.channelName,
                      matchTitle: item.notificationEvent?.matchTitle,
                      eventTitle: item.notificationEvent?.eventTitle,
                      title: item.notificationEvent.title,
                      body: item.notificationEvent.body,
                    };
                    const content = notificationContent(contentData, t);
                    return (
                      <div key={item.id}>
                        <ListItemButton
                          key={item.id}
                          onClick={() => {
                            router.push(item.notificationEvent.actionUrl);
                            onClose();
                          }}
                          sx={{
                            padding: '16px',
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '8px',
                          }}
                        >
                          <Avatar
                            src={
                              contentData.studioEventType === 'system' ||
                              contentData.studioEventType === 'marketing'
                                ? 'https://firebasestorage.googleapis.com/v0/b/camerafi-live-1139.appspot.com/o/Studio_logo.png?alt=media&token=13474828-06e8-4156-b72b-f2905ad8ca01'
                                : item.notificationEvent?.imageUrl ?? ''
                            }
                            sx={{ width: '60px', height: '60px' }}
                          />
                          <Box
                            sx={{
                              width: 'calc(23.5rem - 100px)',
                              minWidth: 0,
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography
                                variant="body3"
                                sx={{
                                  color: 'gray.600',
                                  display: 'flex',
                                  width: '100%',
                                  whiteSpace: 'nowrap',
                                  overflowX: 'hidden',
                                }}
                              >
                                {content?.title?.start}
                                <Typography
                                  component="span"
                                  variant="body3Bold"
                                  sx={{
                                    color: 'gray.600',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {content?.title?.bold}
                                </Typography>
                                {content?.title?.end}
                              </Typography>
                              {!item.isRead && (
                                <Box
                                  sx={{
                                    content: '"ㅤ"',
                                    width: '6px',
                                    height: '6px',
                                    backgroundColor: '#f34c55',
                                    borderRadius: '50%',
                                  }}
                                />
                              )}
                            </Stack>
                            <Typography
                              variant="body2"
                              sx={{
                                color: 'themeInverseColor',
                              }}
                            >
                              {content?.description?.start}
                              <Typography
                                component="span"
                                variant="body2Bold"
                                sx={{
                                  color: 'themeInverseColor',
                                }}
                              >
                                {content?.description?.bold}
                              </Typography>
                              {content?.description?.end}
                            </Typography>
                            <Typography
                              variant="body3"
                              sx={{
                                color: 'gray.600',
                                paddingTop: '4px',
                              }}
                            >
                              {translateTimeLabel(
                                notificationTimeFormatter(
                                  item.notificationEvent.createdAt,
                                ),
                              )}
                            </Typography>
                          </Box>
                        </ListItemButton>
                        <Divider
                          sx={{
                            height: '1px',
                            padding: '0px',
                            backgroundColor: 'gray.100',
                            borderColor: 'gray.100',
                            opacity: '0.8',
                            margin: isMobile ? '0px' : '0px 16px',
                          }}
                        />
                      </div>
                    );
                  })}
                </Fragment>
              ))}
              {hasNextPage && <Box ref={ref} />}
              {isFetchingNextPage && (
                <>
                  {Array.from({ length: 4 }, (_, index) => (
                    <Skeleton
                      key={index}
                      variant="rounded"
                      sx={{
                        height: '98px',
                        borderBottom: '1px solid',
                        borderColor: 'gray.50',
                      }}
                    />
                  ))}
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '109px',
                }}
              >
                <Typography variant="body2" sx={{ color: 'gray.500' }}>
                  {t('notificationMenu.only14days')}
                </Typography>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: isMobile ? '100%' : '634px',
              }}
            >
              <Box
                sx={{
                  width: '225px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '155px',
                  gap: '16px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '70px',
                    height: '70px',
                    borderRadius: '8.75px',
                    backgroundColor: 'rgba(213, 243, 219, 1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <NotificationsOffIcon
                    sx={{
                      color: 'primary.main',
                      width: '40px',
                      height: '40px',
                      padding: '0px',
                    }}
                  />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h5Bold">
                    {t('notificationMenu.noData.title')}
                  </Typography>
                  <Typography variant="body2">
                    {t('notificationMenu.noData.description')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </List>
    );
  }

  return null;
};

export default HistoryMenu;
