import { useMemo } from 'react';

import { useRouter } from 'next/router';

import { useTheme } from '@mui/material';
import { getCookie } from 'cookies-next';

import iOSCommunication from 'utils/iOSCommunication';

const useStudioAdSetting = () => {
  const { locale, route, push } = useRouter();
  const {
    palette: { primaryGradientColor, adGradientColor },
  } = useTheme();

  const isAboutPage = route === '/about';
  const isMainPage = route === '/';
  const isMobileDevice =
    typeof navigator !== 'undefined' &&
    /Android|Mobile|iPhone|iPad|iPod/i.test(navigator.userAgent);

  const isAndroid =
    typeof navigator !== 'undefined' &&
    !/iPhone|iPad|iPod/i.test(navigator.userAgent) &&
    /Android|Mobile/i.test(navigator.userAgent);

  const osType = getCookie('osType');

  const isAppUser = iOSCommunication.isIOSAPP || osType === 'android';

  /*
  <앱설치 안내문> 
  */
  // ? 쿠키 이름변경을 통해 이전 쿠키를 무효화하여 다시 띄울 수 있음
  const APP_INSTALL_GUIDE_COOKIE_NAME = 'appInstallGuide_240722';

  const guideClosedCookie = getCookie(APP_INSTALL_GUIDE_COOKIE_NAME);
  const showIOSGuide =
    typeof navigator !== 'undefined' &&
    /iPhone|iPad|iPod/i.test(navigator.userAgent) &&
    !iOSCommunication.isIOSAPP;
  const showAndroidGuide =
    typeof navigator !== 'undefined' &&
    !/iPhone|iPad|iPod/i.test(navigator.userAgent) &&
    /Android|Mobile/i.test(navigator.userAgent) &&
    osType !== 'android';

  const showAppInstallGuide =
    !guideClosedCookie && (showIOSGuide || showAndroidGuide);

  /*
    <띠배너> 
    필요정보 : [텍스트] [텍스트색] [배경색] [클릭이벤트] 혹은 [40px높이 이미지]
    노출장소 : 랜딩제외하고 노출
    참고사항 : ko와 en을 따로 만들어둔 이유는 한국한정 이벤트가 존재하기 때문
  */
  const showLineBanner = !isAboutPage;
  const lineBannerInfo: Record<
    string,
    {
      text: string;
      textColor: string;
      background: string;
      onClick: () => void;
    }[]
  > = {
    ko: [
      // default
      {
        text: '당신의 스포츠를 전 세계와 공유하세요',
        textColor: 'white',
        background: primaryGradientColor,
        onClick: () => {
          push('/');
        },
      },
      {
        text: '2024 고양 SENDA 스토브리그, 스튜디오에서 ⚽️',
        textColor: '#000000',
        background: '#FFE070',
        onClick: () => {
          push(
            `/channel/@User-0ZJO4rRXTF?utm_source=studio&utm_medium=studio_line_banner&utm_campaign=goyang_2409 `,
          );
        },
      },
      {
        text: '🎉 카메라파이 스튜디오, 앱 스토어에서 만나요',
        textColor: 'white',
        background: adGradientColor,
        onClick: () => {
          if (isAppUser)
            push(
              `/event/${locale === 'en' ? 505 : 506}?utm_source=studio&utm_medium=line_banner&utm_campaign=iOS_studio&utm_id=iOS`,
            );
          else if (isAndroid)
            window.open(
              'https://play.google.com/store/apps/details?id=com.camerafi.studio.twa',
              '_blank',
            );
          else window.open('https://apple.co/3UXOwQS', '_blank');
        },
      },
    ],
    en: [
      // default
      {
        text: 'Share all your sports with the world',
        textColor: 'white',
        background: primaryGradientColor,
        onClick: () => {
          push('/');
        },
      },
      {
        text: 'CameraFi Studio, Rugby Added! 🏈',
        textColor: '#000000',
        background: '#FF6E00',
        onClick: () => {
          window.open(
            'https://blog.camerafi.com/2024/08/rugby-scoreboard-user-guide.html',
          );
          push(
            '/match/00ITsSHjt3wdo79X9iuu?utm_source=studio&utm_medium=studio_line_banner&utm_campaign=pickleball',
          );
        },
      },
      {
        text: '🎉 CameraFi STUDIO is Now Available on iOS',
        textColor: 'white',
        background: adGradientColor,
        onClick: () => {
          if (isAppUser)
            push(
              `/event/${locale === 'en' ? 505 : 506}?utm_source=studio&utm_medium=line_banner&utm_campaign=iOS_studio&utm_id=iOS`,
            );
          else if (isAndroid)
            window.open(
              'https://play.google.com/store/apps/details?id=com.camerafi.studio.twa',
              '_blank',
            );
          else window.open('https://apple.co/3UXOwQS', '_blank');
        },
      },
    ],
  };

  /*
    <메인배너> 
    필요정보 : [이미지 1920x400] [클릭URL] [로컬링크여부]
    노출장소 : 메인페이지에서 웹에서만 노출
  */
  const showMainPageBanner = isMainPage;
  // ! 이 값을 바꿔줘야 기존 이미지 캐시된것을 무시하고 새로 이미지를 다운 받을 수 있다.
  // ! 바꿔줄때는 해당 이미지 이름도 함께 바꿔줘야함
  const updatedDate = '0701';
  const mainPageBannerInfoList: Record<
    string,
    {
      imageLink: string;
      clickLink: string;
      isLocalLink: boolean;
      postEndNextDay?: string; // 종료다음날
    }[]
  > = useMemo(
    () => ({
      ko: [
        // {
        //   imageLink: `/img/ad_studio/CameraFi_OBSBOT_banner_ko.webp`,
        //   clickLink:
        //     '/event/1470?utm_source=studio&utm_medium=banner&utm_campaign=obsbot_supporters',
        //   isLocalLink: true,
        //   postEndNextDay: '2024-07-22',
        // },
        {
          imageLink: `/img/ad_studio/banner0_${updatedDate}_ko.webp`,
          clickLink:
            '/event/1044?utm_source=studio&utm_medium=studio_main_banner&utm_campaign=iOS_studio',
          isLocalLink: true,
        },
        {
          imageLink: `/img/ad_studio/banner1_${updatedDate}_ko.webp`,
          clickLink: 'https://cafe.naver.com/camerafilive/4326',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner2_${updatedDate}_ko.webp`,
          clickLink: 'https://cafe.naver.com/camerafilive/4464',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner3_${updatedDate}_ko.webp`,
          clickLink: 'https://cafe.naver.com/camerafilive/4324',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner4_${updatedDate}_ko.webp`,
          clickLink: 'https://cafe.naver.com/camerafilive/4330',
          isLocalLink: false,
        },
      ],
      en: [
        // {
        //   imageLink: `/img/ad_studio/CameraFi_OBSBOT_banner_en.webp`,
        //   clickLink:
        //     '/event/1471?utm_source=studio&utm_medium=banner&utm_campaign=obsbot_supporters',
        //   isLocalLink: true,
        //   postEndNextDay: '2024-07-22',
        // },
        {
          imageLink: `/img/ad_studio/banner1_${updatedDate}_en.webp`,
          clickLink:
            'https://blog.camerafi.com/2023/07/camerfi-studio-guide-for-beginners-3.html',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner2_${updatedDate}_en.webp`,
          clickLink:
            'https://blog.camerafi.com/2023/11/camerafi-studio-guide-for-beginners-6.html',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner3_${updatedDate}_en.webp`,
          clickLink:
            'https://blog.camerafi.com/2023/07/camerafi-studio-guide-for-beginners-1.html',
          isLocalLink: false,
        },
        {
          imageLink: `/img/ad_studio/banner4_${updatedDate}_en.webp`,
          clickLink:
            'https://blog.camerafi.com/2023/07/camerafi-studio-guide-for-beginners-5.html',
          isLocalLink: false,
        },
      ],
    }),
    [updatedDate],
  );

  const mainPageBannerInfo = useMemo(
    () =>
      mainPageBannerInfoList[locale || 'en'].filter((banner) => {
        const isEndlessBanner = !banner.postEndNextDay;
        const nineHours = 1000 * 60 * 60 * 9;
        const isNotExpiredBanner =
          banner.postEndNextDay &&
          new Date(banner.postEndNextDay).getTime() - nineHours >=
            new Date().getTime();

        return isEndlessBanner || isNotExpiredBanner;
      }),
    [mainPageBannerInfoList, locale],
  );

  /*
    <모바일 팝업> 
    필요정보 : [이미지 제한없음] [클릭URL]
    노출장소 : 메인페이지에서 모바일에서만 노출
    참고사항 : 이미지 선명도 때문에 !유일하게 png 사용, 1.5배로 크게
            ! 쿠키이름을 바꿔서 기존 안보기했던 사용자도 다시뜨게 할수있음 업데이트 주기가 짧다면 바꿔주기
            ! 이미지 이름 바꿔서 기존에 캐시되어있던 이미지 무시하기
            이미지, 버튼 클릭시 각각 다른 URL로 이동하게 가능
  */
  type MobilePopupInfo = {
    cookieName: string;
    imageLink: string;
    imageWidth: number;
    imageHeight: number;
    onClick: () => void;
  };
  const showMobilePopup = isMainPage && isMobileDevice;
  const mobilePopupInfoList: {
    default: MobilePopupInfo;
    promotion?: MobilePopupInfo;
    promotionEndNextDay?: string;
  } = useMemo(
    () => ({
      default: {
        cookieName: 'mobilePopup_APP_240722',
        imageLink: `/img/ad_studio/instagram_mobilePopup240722_${locale}.png`,
        imageWidth: 467 * 2,
        imageHeight: 596 * 2,
        onClick: () => {
          window.open('https://www.instagram.com/p/DAr8bmDSQMN/', '_blank');
        },
      },
      // promotion: {
      //   cookieName: 'mobilePopup_APP_240708',
      //   imageLink: `/img/ad_studio/popup240708_${locale}.png`,
      //   imageWidth: 467 * 2,
      //   imageHeight: 596 * 2,
      //   onClick: () => {
      //     window.open(
      //       locale === 'ko'
      //         ? 'https://studio.camerafi.com/ko/event/1470?utm_source=studio&utm_medium=popup&utm_campaign=obsbot_supporters'
      //         : 'https://studio.camerafi.com/event/1471?utm_source=studio&utm_medium=popup&utm_campaign=obsbot_supporters',
      //       '_blank',
      //     );
      //   },
      // },
      // promotionEndNextDay: '2024-07-22', // 바뀌어야하는 날
    }),
    [locale],
  );

  const mobilePopupInfo = useMemo(() => {
    if (
      mobilePopupInfoList.promotion === undefined ||
      mobilePopupInfoList.promotionEndNextDay === undefined
    )
      return mobilePopupInfoList.default;

    const nineHours = 1000 * 60 * 60 * 9;
    const promotionEndNextDay =
      new Date(mobilePopupInfoList.promotionEndNextDay).getTime() - nineHours;
    const currentDate = new Date().getTime();

    return promotionEndNextDay >= currentDate
      ? mobilePopupInfoList.promotion
      : mobilePopupInfoList.default;
  }, [mobilePopupInfoList]);

  /*
    <이벤트 메인 상단배너> 
    필요정보 : [이미지 1200x200] [클릭URL] 필요하다면 [텍스트]
    노출장소 : 이벤트 메인페이지에서 웹에서만 노출
    참고사항 : 이미지와 텍스트 따로 받아서 반응형 가능
  */
  const eventPageBannerInfo =
    locale === 'ko'
      ? {
          imageLink: '/img/ad_studio/event_banner.webp',
          clickLink:
            '/channel/@marathon?utm_source=studio&utm_medium=event_banner&utm_campaign=marathon&utm_id=marathon',
          isLocalLink: true,
          isDefaultBanner: false,
          text: {
            // title: "이제는 여러분의 이벤트가 주목을 받을 시간입니다",
            // description: "스포츠 대회들로부터 각종 세미나까지, 여러분의 열정을 함께 공유하세요",
            title: '마라톤 대회 소식을 한눈에! 🏃✨',
            titleColor: 'black',
            description: '국내 마라톤 대회 알림 채널 팔로우하고 알림받으세요.',
            descriptionColor: 'black',
          },
        }
      : {
          imageLink: '/img/ad_studio/default_event_banner.webp',
          clickLink: null,
          isLocalLink: false,
          isDefaultBanner: true,
          text: {
            title: 'Your Event’s Moment in the Spotlight',
            titleColor: 'white',
            description:
              'From sports competitions to various seminars, let’s share passion together',
            descriptionColor: 'white',
          },
        };

  return {
    showLineBanner,
    lineBannerInfo,
    showMainPageBanner,
    mainPageBannerInfo,
    showMobilePopup,
    mobilePopupInfo,
    eventPageBannerInfo,
    showAppInstallGuide,
    showAndroidGuide,
    APP_INSTALL_GUIDE_COOKIE_NAME,
  };
};

export default useStudioAdSetting;
