import { useRouter } from 'next/router';

import useActiveAuth from './useActiveAuth';

const CAPTION_LIVE_PAGE = '/caption/live/[encryptedURL]/';
const CAPTION_PAGE = '/caption/[encryptedURL]';
const CHANNEL_PAGE = '/channel/[handle]';
const CONTROL_PAGE = '/control/[matchId]';
const EVENT_DISPLAY_PAGE = '/event/display/[displayEventId]';
const EVENT_EDIT_PAGE = '/event/edit/[editEventId]';
const EVENT_DETAIL_PAGE = '/event/[eventId]';
const EVENT_CREATE_PAGE = '/event/create';
const EVENT_MAIN_PAGE = '/event';
const MATCH_DETAIL_PAGE = '/match/[matchId]';
const ERROR_PAGE = '/404';
const LANDING_PAGE = '/about';
const MAIN_PAGE = '/';
const MEMBERSHIP_PAGE = '/membership';
const SEARCH_PAGE = '/search';
const SETTINGS_PAGE = '/settings';
const PLAYLIST_PAGE = '/playlist/[playlistId]';
const POST_PAGE = '/post/[postId]';

const useAppBarElementData = (isMobile: boolean) => {
  const { channel } = useActiveAuth();
  const { route, query } = useRouter();

  const isMyChannel =
    route === CHANNEL_PAGE && query.handle === `@${channel?.nickname}`;

  const defaultValue = {
    logo: true,
    backBtn: false,
    pageTitle: false,
    searchBtn: true,
    notificationBtn: true,
    moreBtn: false,
  };

  if (!isMobile) return defaultValue;

  switch (route) {
    case CHANNEL_PAGE:
      if (isMyChannel) {
        return {
          logo: true,
          backBtn: false,
          pageTitle: false,
          searchBtn: true,
          notificationBtn: false,
          moreBtn: true,
        };
      } else {
        return {
          logo: false,
          backBtn: true,
          pageTitle: false,
          searchBtn: true,
          notificationBtn: false,
          moreBtn: true,
        };
      }

    case MAIN_PAGE:
    case EVENT_MAIN_PAGE:
      return {
        logo: true,
        backBtn: false,
        pageTitle: false,
        searchBtn: true,
        notificationBtn: true,
        moreBtn: false,
      };

    case CAPTION_PAGE:
    case CAPTION_LIVE_PAGE:
    case SEARCH_PAGE:
    case EVENT_EDIT_PAGE:
    case EVENT_CREATE_PAGE:
    case MEMBERSHIP_PAGE:
    case CONTROL_PAGE:
    case SETTINGS_PAGE:
    case POST_PAGE:
      return {
        logo: false,
        backBtn: true,
        pageTitle: true,
        searchBtn: false,
        notificationBtn: false,
        moreBtn: false,
      };

    case EVENT_DETAIL_PAGE:
    case MATCH_DETAIL_PAGE:
      return {
        logo: false,
        backBtn: true,
        pageTitle: false,
        searchBtn: true,
        notificationBtn: false,
        moreBtn: true,
      };

    case EVENT_DISPLAY_PAGE:
    case ERROR_PAGE:
    case LANDING_PAGE:
      return {
        logo: false,
        backBtn: true,
        pageTitle: false,
        searchBtn: false,
        notificationBtn: false,
        moreBtn: false,
      };

    case PLAYLIST_PAGE:
      return {
        logo: false,
        backBtn: true,
        pageTitle: true,
        searchBtn: false,
        notificationBtn: false,
        moreBtn: true,
      };

    default:
      return defaultValue;
  }
};

export default useAppBarElementData;
