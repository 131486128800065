import DefaultDialog from './DefaultDialog';
import DefaultDialogActions from './DefaultDialogActions';
import DefaultDialogButton from './DefaultDialogButton';
import DefaultDialogComponent from './DefaultDialogComponent';
import DefaultDialogContent from './DefaultDialogContent';
import DefaultDialogTitle from './DefaultDialogTitle';
import DeleteDialogComponent from './DeleteDialogComponent';
import MessageDialogComponent from './MessageDialogComponent';
import MobileMessageDialogComponent from './MobileMessageDialogComponent';

const CommonDialog = {
  Dialog: DefaultDialog,
  DialogTitle: DefaultDialogTitle,
  DialogContent: DefaultDialogContent,
  DialogActions: DefaultDialogActions,
  Button: DefaultDialogButton,

  DialogComponent: DefaultDialogComponent,
  Delete: DeleteDialogComponent,
  MessageComponent: MessageDialogComponent,
  MobileMessageDialog: MobileMessageDialogComponent,
};

export default CommonDialog;
