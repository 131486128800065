import type { ReactElement } from 'react';
import { Fragment, Suspense, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import NextArrowIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BrightnessIcon from '@mui/icons-material/Brightness4';
import CheckIcon from '@mui/icons-material/Check';
import CardIcon from '@mui/icons-material/CreditCard';
import HelpIcon from '@mui/icons-material/HelpRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import type { DialogProps } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { setCookie } from 'cookies-next';

import { StudioSymbolIcon, Translate } from 'components/atoms/Icons';
import { NextLinkComposed } from 'components/atoms/Link';
import PlusBadge from 'components/atoms/PlusBadge';
import ComponentGuard from 'components/templates/ComponentGuard';
import Footer from 'components/templates/Footer';

import useActiveAuth from 'hooks/useActiveAuth';
import useDarkMode from 'hooks/useDarkMode';
import useNumberSystemConverter from 'hooks/useNumberSystemConverter';
import useToggle from 'hooks/useToggle';

import { getLanguageNameFromCodeByIntl } from 'utils/internationalization';

import ChannelAccessSelectionDialog from './ChannelAccessSelectionDialog';
import ResponsiveBannerAdCard from './GoogleAds/ResponsiveBannerAdCard';

const MoreDialogListButton = ({
  to,
  buttonText,
  startIcon,
  target,
  onClick,
  onClose,
}: {
  to: string;
  buttonText: string;
  startIcon: ReactElement;
  target?: string;
  onClick?: () => void;
  onClose?: () => void;
}) => {
  return (
    <Box
      component={NextLinkComposed}
      to={to}
      target={target}
      onClick={() => {
        if (onClick) onClick();
        if (onClose) onClose();
      }}
      sx={{
        display: 'flex',
        cursor: 'default',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1.5,
        my: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {startIcon}
        <Typography variant="subtitle2">{buttonText}</Typography>
      </Box>
      {onClick ? null : (
        <NextArrowIcon
          sx={{ color: 'gray.500', width: '16px', height: '16px' }}
        />
      )}
    </Box>
  );
};

const MoreDialog = ({
  open,
  onClose,
}: {
  onClose: () => void;
} & Omit<DialogProps, 'onClose' | 'content'>) => {
  const { t } = useTranslation('common');
  const { isAuthenticatedUser, isSubscribedUser, channel, signOut, signIn } =
    useActiveAuth();
  const { asPath, locale, pathname, query, push } = useRouter();
  const {
    darkModeActive,
    switchToDarkMode,
    switchToLightMode,
    switchToAutoMode,
    autoModeActive,
  } = useDarkMode();
  const {
    palette: { mode },
  } = useTheme();
  const {
    isOpen: isChannelDialogOpen,
    open: openChannelDialog,
    close: closeChannelDialog,
  } = useToggle();
  const {
    isOpen: isListItemOpen,
    open: listItemOpen,
    close: listItemClose,
  } = useToggle();

  const [isThemeMenuOpen, setThemeMenuOpen] = useState(false);

  const handleListItemShow = (value: boolean) => {
    if (value) listItemOpen();
    else listItemClose();
  };

  const channelInfo = [
    {
      id: 'follower',
      count: useNumberSystemConverter(channel?.followerCount),
      link: `/channel/@${channel.nickname}/?tab=matches`,
    },
    {
      id: 'match',
      count: useNumberSystemConverter(channel?.matchCount),
      link: `/channel/@${channel.nickname}/?tab=matches`,
    },
    {
      id: 'event',
      count: useNumberSystemConverter(channel?.eventCount),
      link: `/channel/@${channel.nickname}/?tab=events`,
    },
  ];

  const switchLanguage = () => {
    setCookie('locale', locale === 'en' ? 'ko' : 'en', {
      maxAge: 60 * 60 * 24 * 365 * 1000,
    });
    push({ pathname: pathname, query: query }, asPath, {
      locale: locale === 'en' ? 'ko' : 'en',
    });
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} sx={{ zIndex: 1000 }}>
      <DialogContent sx={{ px: '20px', mb: 5, overflowX: 'hidden' }}>
        <ComponentGuard
          loadingFallback={
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                  padding: '8px 0px 20px 0px',
                }}
              >
                <Skeleton
                  variant="circular"
                  height={40}
                  width={40}
                  sx={{ marginRight: 2 }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Skeleton variant="rounded" height={30} width={180} />
                  <Skeleton variant="rounded" height={21} width={180} />
                </Box>
                <Skeleton
                  variant="rounded"
                  sx={{
                    borderRadius: '100px',
                    ml: 'auto',
                    mb: 'auto',
                  }}
                  height={30}
                  width={60}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 0.5,
                  mb: 2.5,
                  padding: '8px 0',
                }}
              >
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    height={75}
                    width={'100%'}
                  />
                ))}
              </Box>
            </>
          }
          nonLoginFallback={
            <Box
              onClick={() => signIn()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                pb: 3,
                pt: 2,
              }}
            >
              <Typography variant="h5Bold">
                {t('navigationBar.loginSignUp')}
              </Typography>
              <Typography variant="h6Bold" sx={{ color: 'gray.500' }}>
                {t('navigationBar.loginDescription')}
              </Typography>
            </Box>
          }
        >
          <Box
            onClick={async () => {
              await push(`/channel/@${channel.nickname}`);
              onClose();
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pt: 1,
              pb: '20px',
              mb: '12px',
            }}
          >
            <Avatar
              alt={channel.channelName ?? 'channel-image'}
              src={channel.profileUrl ?? ''}
              sx={{
                width: '40px',
                height: '40px',
                mr: 2,
                '& svg': { ml: 0 },
              }}
            />
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  variant="h4Bold"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflowWrap: 'anywhere',
                    wordBreak: 'break-all',
                    mb: 0.5,
                  }}
                >
                  {channel.channelName ?? 'Unknown Channel'}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 9,
                    p: '5px 11px',
                    height: '30px',
                    minWidth: 'auto',
                    bgcolor: 'rgba(55, 197, 86, 0.1)',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={async () => {
                    await push(`/channel/@${channel.nickname}`);
                    onClose();
                  }}
                >
                  <Typography variant="chip">
                    {t('navigationBar.myChannel2')}
                  </Typography>
                </Button>
              </Box>
              {channel?.nickname ? (
                <Typography
                  variant="h6"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflowWrap: 'anywhere',
                    color: 'gray.400',
                  }}
                >
                  {`@${channel.nickname}`}
                </Typography>
              ) : (
                <Skeleton variant="text" width="100px" height="21px" />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              background: 'rgba(55, 197, 86, 0.10)',
              borderRadius: '8px',
              p: 1,
              mb: 2.5,
            }}
          >
            {channelInfo.map((info, idx) => (
              <Fragment key={info.id}>
                <Box
                  onClick={() => {
                    push(info.link);
                    onClose();
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: '12px',
                    flex: 1,
                  }}
                >
                  <Typography variant="h4Bold">{info.count}</Typography>
                  <Typography variant="h6" sx={{ color: 'gray.400' }}>
                    {t(`navigationBar.${info.id}`)}
                  </Typography>
                </Box>
                {idx !== channelInfo.length - 1 ? (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      borderColor:
                        mode === 'dark' ? 'gray.200' : 'deprecatedGray.80',
                    }}
                  />
                ) : null}
              </Fragment>
            ))}
          </Box>
        </ComponentGuard>

        <Box
          sx={({ palette: { mode } }) => ({
            width: 'calc(100% + 40px)',
            height: '10px',
            ml: '-20px',
            bgcolor: mode === 'dark' ? 'gray.100' : 'gray.50',
          })}
        />

        <MoreDialogListButton
          to="/membership"
          buttonText={t('navigationBar.pricingAndMembership')}
          startIcon={<CardIcon sx={{ color: 'gray.500' }} />}
          onClose={onClose}
        />
        <ComponentGuard
          loadingFallback={
            <Skeleton
              variant="rounded"
              height={48}
              sx={{
                my: '8px',
              }}
            />
          }
        >
          <ErrorBoundary fallback={<></>}>
            <Suspense
              fallback={
                <Skeleton
                  variant="rounded"
                  height={48}
                  sx={{
                    my: '8px',
                  }}
                />
              }
            >
              <ChannelAccessSelectionDialog
                open={isChannelDialogOpen}
                onClose={closeChannelDialog}
                handleListItemShow={handleListItemShow}
              />
            </Suspense>
          </ErrorBoundary>

          <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />

          {isListItemOpen && (
            <Box
              onClick={() => {
                openChannelDialog();
              }}
              sx={{
                display: 'flex',
                cursor: 'default',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 1.5,
                my: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <StudioSymbolIcon sx={{ color: 'gray.500' }} />
                <Typography variant="subtitle2">{t('otherChannel')}</Typography>
                <PlusBadge
                  size="small"
                  backgroundColor={mode === 'dark' ? '#1D1D21' : 'white'}
                />
              </Box>
              <NextArrowIcon
                sx={{ color: 'gray.500', width: '16px', height: '16px' }}
              />
            </Box>
          )}
        </ComponentGuard>

        {!isSubscribedUser ? (
          <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />
        ) : null}

        <ResponsiveBannerAdCard
          adId="모든_더보기팝업중간_배너_모바일"
          sx={{ my: 1 }}
        />

        {isSubscribedUser ? (
          <Box
            sx={({ palette: { mode } }) => ({
              width: 'calc(100% + 40px)',
              height: '10px',
              ml: '-20px',
              bgcolor: mode === 'dark' ? 'gray.100' : 'gray.50',
            })}
          />
        ) : (
          <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />
        )}

        {isAuthenticatedUser ? (
          <MoreDialogListButton
            to="/settings"
            buttonText={t('navigationBar.channelSetting')}
            startIcon={<SettingsIcon sx={{ color: 'gray.400' }} />}
            onClose={onClose}
          />
        ) : (
          <Box sx={{ py: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 2,
              }}
              onClick={switchLanguage}
            >
              <Translate
                sx={{
                  color: 'gray.400',
                  width: '22px',
                  height: '22px',
                  mr: 1,
                }}
              />
              <Typography variant="h6" sx={{ color: 'gray.500' }}>{`${t(
                'navigationBar.language',
              )}: ${getLanguageNameFromCodeByIntl(
                locale ?? 'en',
                locale,
              )}`}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                py: 2,
              }}
              onClick={() => setThemeMenuOpen(!isThemeMenuOpen)}
            >
              <BrightnessIcon
                sx={{
                  color: 'gray.400',
                  width: '22px',
                  height: '22px',
                  mr: 1,
                }}
              />

              <Typography variant="h6" sx={{ color: 'gray.500' }}>{`${t(
                'navigationBar.appearance',
              )}: ${
                autoModeActive
                  ? t('navigationBar.autoTheme')
                  : darkModeActive
                    ? t('navigationBar.darkTheme')
                    : t('navigationBar.lightTheme')
              }`}</Typography>
            </Box>

            <Collapse in={isThemeMenuOpen} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                sx={{
                  mt: 1,
                  '.MuiListItemIcon-root': { minWidth: '32px' },
                  svg: { width: '20px', height: '20px' },
                }}
              >
                <ListItemButton
                  sx={{ py: 0.5 }}
                  onClick={() => {
                    switchToAutoMode();
                  }}
                >
                  <ListItemIcon>
                    {autoModeActive ? <CheckIcon fontSize="small" /> : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={t('navigationBar.autoTheme')}
                    sx={{ '& span': { fontSize: '14px' } }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ py: 0.5 }}
                  onClick={() => {
                    switchToDarkMode();
                  }}
                >
                  <ListItemIcon>
                    {darkModeActive && !autoModeActive ? (
                      <CheckIcon fontSize="small" />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={t('navigationBar.darkTheme')}
                    sx={{ '& span': { fontSize: '14px' } }}
                  />
                </ListItemButton>
                <ListItemButton
                  sx={{ py: 0.5 }}
                  onClick={() => {
                    switchToLightMode();
                  }}
                >
                  <ListItemIcon>
                    {!darkModeActive && !autoModeActive ? (
                      <CheckIcon fontSize="small" />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText
                    primary={t('navigationBar.lightTheme')}
                    sx={{ '& span': { fontSize: '14px' } }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </Box>
        )}

        <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />

        <MoreDialogListButton
          target="_blank"
          to={
            locale === 'ko'
              ? 'https://cafe.naver.com/camerafilive/4384 '
              : 'https://blog.camerafi.com/p/tutorials-how-to-use-camerafi-studio.html'
          }
          buttonText={t('navigationBar.help')}
          startIcon={<HelpIcon sx={{ color: 'gray.400' }} />}
        />

        <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />

        <MoreDialogListButton
          to="/about"
          buttonText={t('navigationBar.about')}
          startIcon={<StudioSymbolIcon sx={{ color: 'gray.500' }} />}
          onClose={onClose}
        />

        <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />

        {isAuthenticatedUser ? (
          <>
            <MoreDialogListButton
              to="/"
              buttonText={t('navigationBar.signOut')}
              startIcon={<LogoutIcon sx={{ color: 'gray.400' }} />}
              onClick={() => {
                signOut();
                onClose();
              }}
            />
            <Divider sx={{ width: 'calc(100% + 40px)', ml: '-20px' }} />
          </>
        ) : null}

        <Footer.MoreDialog />
      </DialogContent>
    </Dialog>
  );
};

export default MoreDialog;
