import { memo } from 'react';

import {
  DialogActions,
  type DialogActionsProps,
  type SxProps,
} from '@mui/material';

interface DefaultDialogActionsProps extends Omit<DialogActionsProps, 'sx'> {
  sx?: SxProps;
}

const DefaultDialogActions = ({
  children,
  sx,
  ...props
}: DefaultDialogActionsProps) => (
  <DialogActions
    sx={{ p: { xs: '30px 16px', sm: '16px 30px 30px' }, ...sx }}
    {...props}
  >
    {children}
  </DialogActions>
);

export default memo(DefaultDialogActions);
