import useTranslation from 'next-translate/useTranslation';

import useActiveAuth from 'hooks/useActiveAuth';

import CommonDialog from '../CommonDialog';

const LoginRequiredDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation('common');
  const { signIn } = useActiveAuth();

  return (
    <CommonDialog.MessageComponent
      title={t('loginRequiredDialog.title')}
      description={t('loginRequiredDialog.description')}
      open={isOpen}
      onClose={onClose}
      onAction={() => {
        signIn();
        onClose();
      }}
    />
  );
};

export default LoginRequiredDialog;
