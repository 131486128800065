import { useContext } from 'react';

import { AppBarMenuListContext } from 'contexts/AppBarMenuListContext';

const useAppBarMenuList = () => {
  const context = useContext(AppBarMenuListContext);

  if (!context)
    throw new Error('AuthContext must be placed within AuthProvider');

  return context;
};

export default useAppBarMenuList;
