import type { ReactNode } from 'react';
import { memo } from 'react';

import useTranslation from 'next-translate/useTranslation';

import { Box } from '@mui/material';

import CommonDialog from '../CommonDialog';
import type { DefaultDialogProps } from './DefaultDialog';

interface DefaultDialogComponentProps extends DefaultDialogProps {
  open: boolean;
  title: string;
  subTitle?: string;
  cancelDisabled?: boolean;
  submitDisabled?: boolean;
  children: ReactNode;
  submitButtonText?: string;
  cancelButtonText?: string;
  fixedComponentAboveButtons?: ReactNode;
  onSubmit: () => void;
  onClose: () => void;
}

const DefaultDialogComponent = ({
  open,
  title,
  subTitle,
  cancelDisabled = false,
  submitDisabled = false,
  children,
  submitButtonText,
  cancelButtonText,
  fixedComponentAboveButtons,
  onSubmit,
  onClose,
  ...props
}: DefaultDialogComponentProps) => {
  const { t } = useTranslation('common');

  return (
    <CommonDialog.Dialog
      open={open}
      onClose={(_, reason) => {
        if (!reason) onClose();
      }}
      {...props}
    >
      <CommonDialog.DialogTitle
        title={title}
        onClose={onClose}
        subTitle={subTitle}
      />

      <CommonDialog.DialogContent>{children}</CommonDialog.DialogContent>

      {fixedComponentAboveButtons ? (
        <Box
          sx={{
            borderTop: '1px solid',
            borderColor: 'dividers.normal',
            pt: { xs: '10px', sm: '16px' },
            px: { xs: '16px', sm: '30px' },
          }}
        >
          {fixedComponentAboveButtons}
        </Box>
      ) : null}

      <CommonDialog.DialogActions
        sx={{
          p: {
            xs: fixedComponentAboveButtons ? '8px 16px 30px' : '30px 16px',
            sm: '16px 30px 30px',
          },
        }}
      >
        <CommonDialog.Button
          variant="outlinedAssistive"
          disabled={cancelDisabled}
          buttonText={cancelButtonText || t('cancel')}
          onClick={onClose}
          sx={{ color: 'gray.700' }}
        />
        <CommonDialog.Button
          variant="solid"
          disabled={submitDisabled}
          buttonText={submitButtonText || t('submit')}
          onClick={onSubmit}
        />
      </CommonDialog.DialogActions>
    </CommonDialog.Dialog>
  );
};

export default memo(DefaultDialogComponent);
