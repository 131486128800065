import type { ReactNode } from 'react';

import type { SxProps, TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

const ResponsiveTypography = ({
  xsVariant,
  smVariant,
  oneLine = false,
  twoLine = false,
  children,
  sx,
  ...props
}: {
  xsVariant: TypographyProps['variant'];
  smVariant: TypographyProps['variant'];
  oneLine?: boolean;
  twoLine?: boolean;
  children?: ReactNode;
} & Omit<TypographyProps, 'variant'>) => {
  const commonSx: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  };

  return (
    <>
      <Typography
        variant={xsVariant}
        sx={{
          ...(oneLine || twoLine
            ? {
                display: { xs: '-webkit-box', sm: 'none' },
                WebkitLineClamp: oneLine ? 1 : 2,
                ...commonSx,
              }
            : {
                display: { xs: 'block', sm: 'none' },
              }),
          ...sx,
        }}
        {...props}
      >
        {children}
      </Typography>
      <Typography
        variant={smVariant}
        sx={{
          ...(oneLine || twoLine
            ? {
                display: { xs: 'none', sm: '-webkit-box' },
                WebkitLineClamp: oneLine ? 1 : 2,
                ...commonSx,
              }
            : {
                display: { xs: 'none', sm: 'block' },
              }),
          ...sx,
        }}
        {...props}
      >
        {children}
      </Typography>
    </>
  );
};

export default ResponsiveTypography;
