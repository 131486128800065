import useTranslation from 'next-translate/useTranslation';

import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';

import ResponsiveButton from 'components/commons/ResponsiveButton';
import ResponsiveTypography from 'components/commons/ResponsiveTypography';

const MessageDialogComponent = ({
  title,
  description,
  open,
  actionText,
  onClose,
  onAction,
}: {
  title: string;
  description: string;
  open: boolean;
  actionText?: string;
  onClose: () => void;
  onAction: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{
        sx: {
          width: { xs: '328px', sm: '460px' },
          height: { xs: '176px', sm: '210px' },
          padding: { xs: '32px 16px 24px', sm: '35px 60px 30px' },
        },
      }}
    >
      <IconButton
        disableTouchRipple
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          alignItems: 'center',
          display: { xs: 'none', sm: 'flex' },
          width: '40px',
          height: '40px',
        }}
      >
        <CloseIcon
          sx={{
            width: '32px',
            height: '32px',
            color: 'icon.normal',
          }}
        />
      </IconButton>

      <DialogTitle component="div" sx={{ textAlign: 'center', p: 0 }}>
        <ResponsiveTypography
          xsVariant="h5Bold"
          smVariant="h4Bold"
          sx={{ mb: 1 }}
        >
          {title}
        </ResponsiveTypography>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', p: 0 }}>
        <ResponsiveTypography
          xsVariant="body2"
          smVariant="body1"
          sx={{
            color: 'text.secondary',
          }}
        >
          {description}
        </ResponsiveTypography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 0 }}>
        <ResponsiveButton
          xsSize="medium"
          smSize="large"
          variant="outlinedAssistive"
          onClick={onClose}
          sx={{ width: { xs: '144px', sm: '146px' } }}
        >
          {t('close')}
        </ResponsiveButton>
        <ResponsiveButton
          xsSize="medium"
          smSize="large"
          variant="solid"
          onClick={onAction}
          sx={{ width: { xs: '144px', sm: '146px' } }}
        >
          {actionText || t('ok')}
        </ResponsiveButton>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialogComponent;
