import useTranslation from 'next-translate/useTranslation';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useChannelApi, {
  GET_MANAGED_CHANNEL_LIST_KEY,
} from 'api/hooks/useChannelApi';

import CommonDialog from 'components/dialogs/CommonDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useManagerAuth from 'hooks/useManagerAuth';

interface ChannelManagerQuitDialogProps {
  isOpen: boolean;
  managerId: number;
  managerNickname: string;
  onCLose: () => void;
}

const ChannelManagerQuitDialog = ({
  isOpen,
  managerId,
  onCLose,
  managerNickname,
}: ChannelManagerQuitDialogProps) => {
  const { user } = useActiveAuth();
  const { clearManagedContext } = useManagerAuth();

  const { deleteInvitedChannel } = useChannelApi();
  const { t } = useTranslation('common');

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => deleteInvitedChannel(managerId),
    onSuccess: async () => {
      onCLose();
      if (managerNickname === user.nickname) {
        await clearManagedContext();
      }
      queryClient.refetchQueries({ queryKey: GET_MANAGED_CHANNEL_LIST_KEY });
      enqueueSnackbar(t('channelQuitDialog.deleteSuccess'), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(t('channelQuitDialog.deleteFail'), {
        variant: 'error',
      });
    },
  });

  return (
    <CommonDialog.Delete
      open={isOpen}
      onClose={onCLose}
      title={t('channelQuitDialog.title')}
      description={t('channelQuitDialog.description')}
      disableDelete={mutation.isPending}
      cancelText={
        mutation.isPending
          ? t('channelQuitDialog.close')
          : t('channelQuitDialog.cancel')
      }
      deleteText={
        mutation.isPending
          ? t('channelQuitDialog.deleting')
          : t('channelQuitDialog.delete')
      }
      onDelete={() => {
        mutation.mutate();
      }}
    />
  );
};

export default ChannelManagerQuitDialog;
