import type { ReactNode } from 'react';
import { memo } from 'react';

import { Dialog, type DialogProps, type SxProps } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

export interface DefaultDialogProps extends Omit<DialogProps, 'sx'> {
  children: ReactNode;
  sx?: SxProps;
}

const DefaultDialog = ({ children, sx, ...props }: DefaultDialogProps) => {
  const { isMobile } = useCheckDevice();

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      onClick={(e) => e.stopPropagation()}
      PaperProps={{
        sx: {
          width: { md: '90%' },
          maxWidth: { md: '736px' },
          height: { md: 'auto' },
          borderRadius: { md: '8px' },
          overflowY: 'auto',

          ...sx,
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default memo(DefaultDialog);
