import type { ReactNode } from 'react';

import type { SxProps } from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import { NextLinkComposed } from 'components/atoms/Link';

const MenuItemButton = ({
  className,
  buttonText,
  icon,
  selected,
  target,
  to,
  onClick,
  sx,
}: {
  className?: string;
  buttonText: string;
  icon: ReactNode;
  selected?: boolean;
  target?: string;
  to?: string;
  onClick: () => void;
  sx?: SxProps;
}) => (
  <ListItemButton
    className={className}
    component={to ? NextLinkComposed : 'a'}
    to={to}
    target={target}
    selected={selected}
    sx={{
      pl: 3,
      borderRadius: 0,
      '&.Mui-selected': {
        color: 'primaryColor.500',
        '& svg': {
          color: 'primaryColor.500',
        },
      },
      ...sx,
    }}
    onClick={onClick}
  >
    <ListItemIcon
      sx={{
        minWidth: 48,
        svg: { width: '20px', color: 'gray.400' },
      }}
    >
      {icon}
    </ListItemIcon>
    <ListItemText primary={buttonText} />
  </ListItemButton>
);

export default MenuItemButton;
