import { type ReactNode, memo } from 'react';

import MoreIcon from '@mui/icons-material/MoreVert';
import type { IconButtonProps, MenuItemProps, PaperProps } from '@mui/material';
import { IconButton, MenuItem, Popover, Typography } from '@mui/material';

import MoreIconWithIconShadow from 'components/atoms/Icons/MoreIcon';

import usePopoverControl from 'hooks/usePopoverControl';

type MoreOptionButtonProps = {
  withIconShadow?: boolean;
  optionList: {
    startIcon?: ReactNode;
    text: string;
    show?: boolean;
    onClick: () => void;
    sx?: MenuItemProps['sx'];
    props?: MenuItemProps;
  }[];
  MenuProps?: PaperProps;
} & IconButtonProps;

const MoreOptionButton = ({
  withIconShadow = false,
  optionList,
  onClick,
  sx,
  MenuProps,
  ...props
}: MoreOptionButtonProps) => {
  const {
    anchorEl,
    open: openPopover,
    close: closePopover,
  } = usePopoverControl();

  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          e.preventDefault();
          openPopover(e);
          onClick && onClick(e);
        }}
        sx={{ width: '32px', height: '32px', ...sx }}
        {...props}
      >
        {withIconShadow ? <MoreIconWithIconShadow /> : <MoreIcon />}
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClick={(e) => e.stopPropagation()}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: { py: 1 },
            ...MenuProps,
          },
        }}
      >
        {optionList
          .filter((option) => option.show !== false)
          .map((option) => (
            <MenuItem
              key={option.text}
              value={option.text}
              onClick={() => {
                option.onClick();
                closePopover();
              }}
              sx={{
                height: '48px',
                pl: 2,
                pr: 2.5,
                gap: 1.5,

                svg: { color: 'icon.normal' },
                ...option.sx,
              }}
              {...option.props}
            >
              {option.startIcon ? option.startIcon : null}
              <Typography variant="body1">{option.text}</Typography>
            </MenuItem>
          ))}
      </Popover>
    </>
  );
};

export default memo(MoreOptionButton);
