import {
  Button,
  type ButtonProps,
  type SxProps,
  Typography,
} from '@mui/material';

const DefaultDialogButton = ({
  buttonText,
  sx,
  ...props
}: {
  buttonText: string;
  sx?: SxProps;
} & Omit<ButtonProps, 'sx'>) => (
  <Button
    size="large"
    sx={{
      width: '160px',
      flex: { xs: 1, sm: 'none' },
      ...sx,
    }}
    {...props}
  >
    <Typography variant="body1Bold">{buttonText}</Typography>
  </Button>
);

export default DefaultDialogButton;
