import type { ReactNode } from 'react';
import { memo } from 'react';

import type { DialogContentProps, SxProps } from '@mui/material';
import { Box, DialogContent } from '@mui/material';

interface DefaultDialogContentProps extends Omit<DialogContentProps, 'sx'> {
  children: ReactNode;
  sx?: SxProps;
}

const DefaultDialogContent = ({
  children,
  sx,
  ...props
}: DefaultDialogContentProps) => (
  <DialogContent
    sx={{
      mt: { xs: '30px', sm: 0 },
      p: { xs: '0 16px', sm: '0 30px' },
      overflowX: 'hidden',

      // 모바일 스크롤바
      '&::-webkit-scrollbar': { width: '8px' },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.200',
        borderRadius: '10px',
      },
      ...sx,
    }}
    {...props}
  >
    <Box
      sx={{
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',

        // pc 스크롤바
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray.200',
          borderRadius: '10px',
        },
      }}
    >
      {children}
    </Box>
  </DialogContent>
);

export default memo(DefaultDialogContent);
